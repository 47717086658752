<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        รายงานการซื้อสินค้า
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="showFilter = !showFilter">
        <v-icon>
          mdi-filter
        </v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mr-4 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" class="pl-4 pb-4">
          <v-card
              style="height: 100%;overflow: auto"
              class="rounded-lg elevation-2"
              outlined
              tile
          >
            <v-data-table
                :items-per-page="20"
                :headers="headers"
                :items="items"
                :header-props="{ sortIcon: null }"
                :loading="$store.state.isLoading"
            >

<!--              <template v-for="[key, value] in Object.entries(header_products)">-->
              <template #[`item.`+value]="{item}" v-for="({text, value}, i) in headers">
                  {{item[value]}}
              </template>

              <template v-slot:item.latest_order_date="{item}">
                {{item.latest_order_date.split('^')[1]}}
<!--                {{DateTime.fromFormat(item.latest_order_date, "yyyy/LL/dd").toFormat("dd/LL/yyyy")}}-->
              </template>
<!--              <template v-slot:body.prepend style="outline: thin solid lightgrey">-->
<!--                <td>-->
<!--                  <strong>This is an extra footer</strong>-->
<!--                </td>-->
<!--                <td>-->
<!--                  <strong>This is an extra footer</strong>-->
<!--                </td>-->
<!--              </template>-->
              <template v-slot:body.append>
                <tr>
                  <td style="outline: thin solid lightgrey" colspan="3"><strong>รวม</strong></td>
                  <td style="outline: thin solid lightgrey" class="text-right"><strong>{{$currency(sum('sum')).format()}}</strong></td>
                  <td
                      v-for="[key, value] of Object.entries(header_products)"
                      class="text-right"
                      style="outline: thin solid lightgrey"
                  >
                    <strong>{{$currency(sum(value)).format()}}</strong>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <FilterCard @search="getData"
                      :search-option="findHeaders"
                      date
                      category-name
                      csv
                      :json-data="csvData"
                      :labels="csvLabels"
                      file-name="รายงานการซื้อสินค้า"
          />
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";
import _ from "lodash";
import DateTime from 'luxon/src/datetime.js'

export default {
  components: {Splitpanes, Pane, FilterCard},
  name: "Product Buy",
  data: () => ({
    showFilter: true,
    search: '',
    items: [],
    queryString: '',
    findHeaders: [
      // {
      //   text: 'เลขออเดอร์',
      //   value: 'order_id',
      //   align: 'center'
      // },
      {
        text: 'ลูกค้า',
        value: 'customer_name',
        align: 'center'
      },
      {
        text: 'สินค้า',
        value: 'product_name',
        align: 'center'
      },
    ],
    csvData: [],
    total_vat: 0,
    header_products: []
  }),
  created() {
    // this.getData()
  },
  computed: {
    headers() {
      let products = []
      for (const [key, value] of Object.entries(this.header_products)) {
        products.push({text: value, value: value, width: 100, align: 'right', cellClass: 'table-line-border'})
      }
      return [
        {text: 'ลูกค้า', value: 'customer_name', width: 200, cellClass: 'table-line-border'},
        {text: 'เบอร์โทร', value: 'customer_tel', cellClass: 'table-line-border'},
        {text: 'วันที่ซื้อล่าสุด (เฉพาะช่วงเวลาที่ค้นหา)', value: 'latest_order_date', width: 100, cellClass: 'table-line-border'},
        {text: 'รวมจำนวน (ทุกหน่วย)', value: 'sum', width: 100, cellClass: 'table-line-border'},
        ...products,
      ]
    },
    csvLabels() {
      let result = {}
      this.headers.forEach(h => {
        result[h.value] = {title: h.text}
      })
      return result
    }
  },
  methods: {
    getData(query) {
      this.$store.state.isLoading = true
      axios.get('/api/dashboards/order_item_paper/' + query)
          .then(res => {
            this.items = res.data.data
            this.generateData(res.data.data)
            this.csvData = [...this.items]
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          }).finally(() =>  this.$store.state.isLoading = false)
    },
    sum(field) {
      return this.items.reduce((accum, item) => Number(accum) + Number(item[field]), 0)
    },
    generateData(data) {
      let products = {}
      data.forEach(si => {
        products[si.product_id+si.product_name] = si.product_name
      })
      this.header_products = products
      const _ = require('lodash');

      const result = _.chain(data)
        .groupBy('customer_name')
        .map((orders, customer_name) => {
          const customerData = _.chain(orders)
            .groupBy('product_name')
            .mapValues(products => _.sumBy(products, 'quantity'))
            .value();

          const latest_order_date = DateTime.fromFormat(_.maxBy(orders, 'order_date').order_date, "yyyy/LL/dd").toFormat("yyyy/LL/dd^dd/LL/yyyy");

          return {
            customer_name,
            customer_tel: _.get(_.head(orders), 'customer_tel', ''),
            latest_order_date,
            ...customerData,
            sum: _.sum(Object.values(customerData) || 0),
          };
        })
        .value();

      result.forEach(r => {
        Object.entries(this.header_products).forEach(([code, name]) => {
          if (!r.hasOwnProperty(name)) {
            r[name] = ''
          }
        })
      })
      this.items = result
    }
  }
}
</script>

<style>
.table-line-border {
  outline: thin solid lightgrey;
}
</style>
